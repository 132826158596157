import { createContext, ReactNode, useState } from 'react'
import { 
  OpenAPI,
  ApiError,
  AuthService,
  Body_login_for_access_token_auth_login_post,
  Token,
  TokenRefresh
} from '../api';
import { jwtDecode, JwtPayload } from 'jwt-decode'
import dayjs from 'dayjs';
import axios from 'axios';

type Props = {
  children?: ReactNode;
}

type IAuthContext = {
  user: string | null;
  isSuperuser: boolean;
  isStuffUser: boolean;
  demoMode: boolean;
  loginUser: (username: string, password: string) => Promise<string>
  logoutUser: () => void;
}

interface CustomJwtPayload extends JwtPayload {
  is_superuser: boolean
  is_stuff: boolean
  demo_mode: boolean
}

const token: Token = localStorage.getItem('authTokens')? 
  JSON.parse(localStorage.getItem('authTokens') as string) : null

const initialLoginUser = (username: string, password: string) => {

  const promise = new Promise<string>((resolve, reject) => {
    reject("not implemented yet!")
  })

  return promise
}

const initialValue: IAuthContext = {
  user: token? jwtDecode<JwtPayload>(token.access_token).sub as string : null,
  isSuperuser: token? jwtDecode<CustomJwtPayload>(token.access_token).is_superuser : false,
  isStuffUser: token? jwtDecode<CustomJwtPayload>(token.access_token).is_stuff     : false,
  demoMode: token? jwtDecode<CustomJwtPayload>(token.access_token).demo_mode     : false,
  loginUser: initialLoginUser,
  logoutUser: () => {}
}


const AuthContext = createContext<IAuthContext>(initialValue)

export default AuthContext;

export const AuthProvider = ({children}: Props) => {
  const [accessToken, setAccessToken] = useState<string | null>(token? token.access_token : null)
  const [refreshToken, setRefreshToken] = useState<string | null>(token? token.refresh_token : null)
  const [user, setUser] = useState<string | null>(initialValue.user)
  const [isSuperuser, setIsSuperuser] = useState(initialValue.isSuperuser)
  const [isStuffUser, setIsStuffUser] = useState(initialValue.isStuffUser)
  const [demoMode, setDemoMode] = useState(initialValue.demoMode)

  const getAccessToken = () => {
    const promise = new Promise<string>((resolve, reject) => {
      if (accessToken == null) {
        resolve("")
      }
      const jwt = accessToken? accessToken : ""
      const jwtPayload: CustomJwtPayload = jwtDecode(jwt)

      if (jwtPayload.exp === undefined) {
        console.log("bad token")
        resolve(accessToken? accessToken: "")
        return
      }

      const seconds = dayjs.unix(jwtPayload.exp).diff(dayjs()) / 1000
      if (seconds < 10 && refreshToken) {
        const body: TokenRefresh = {
          refresh_token: refreshToken
        }
        axios.post<Token>("/auth/refresh", body)
        .then((response) => {
          console.log("postRefreshToken OK")
          updateAcessToken(response.data)
          resolve(response.data.access_token)
        })
        .catch((error: ApiError) => {
          console.log("postRefreshToken error", error)
          logoutUser()
          resolve("")
        })
      } else {
        console.log("good token")
        resolve(accessToken? accessToken: "")    
      }  
    })

    console.log("resp promise")
    return promise
  }

  OpenAPI.TOKEN = getAccessToken

  // useEffect(() => {
  //   console.log("useEffect token")
  //   OpenAPI.TOKEN = test
  // }, [])

  const updateAcessToken = (token: Token) => {
    localStorage.setItem('authTokens', JSON.stringify(token))

    const jwtPayload: CustomJwtPayload = jwtDecode(token.access_token)

    setAccessToken(token.access_token)
    setRefreshToken(token.refresh_token)
    setUser(jwtPayload.sub ? jwtPayload.sub : null)
    setIsSuperuser(jwtPayload.is_superuser ? true : false)
    setIsStuffUser(jwtPayload.is_stuff? true : false)
    setDemoMode(jwtPayload.demo_mode? true : false)
  }


  const loginUser = (username: string, password: string) => {
    const promise = new Promise<string>((resolve, reject) => {
      if (username === "" || password === "") {
        reject(Error("username or password should not be empty"))
        return
      }

      const formData: Body_login_for_access_token_auth_login_post = {
        username, password
      }
      AuthService.loginForAccessTokenAuthLoginPost(formData)
      .then ((response: Token) => {
        updateAcessToken(response)
        resolve("OK")
      })
      .catch((error: ApiError) => {
        reject(error)
      })
    })

    return promise
  }

  const logoutUser = () => {
    setUser(null)
    setAccessToken(null)
    setRefreshToken(null)
    localStorage.removeItem('authTokens')
  }

  const contextData: IAuthContext = {
    user,
    isSuperuser,
    isStuffUser,
    demoMode,
    loginUser,
    logoutUser,
  }

  return (
    <AuthContext.Provider value={contextData}>
      {children}
    </AuthContext.Provider>
  )
}

