import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import UnderConstruction from '../../components/navbar/UnderConstruction';


const Home = () => {

  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener('resize', () => setWindowHeight(
      window.innerHeight > 600 ? (window.innerHeight) : (600)
    ));
  }, []);

  return (
    <>
    <Box
      id="home"
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? "url('/images/background-1.jpg')"
            // 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : "url('/images/background-2.jpg')",
            // `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        // backgroundSize: '100% 20%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        mt: -16
      })}
    >
      <UnderConstruction />
      {/* <Divisions /> */}
      <Typography
        sx={{
          mt: windowHeight/8-20,
        }}
      >
        {' '}
      </Typography>
    </Box>
    </>
  )
}

export default Home
