import React, { useContext, useState } from 'react' 
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import AuthContext from '../../context/AuthContext';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const { loginUser } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleLogin = () => {
    setError("")
    setLoading(true)

    loginUser(email, password)
    .then((response: string) => {
      navigate("/")
    })
    .catch((error: Error) => {
      setError(`⚠️ ${error}`)
    })
    .finally(() => {
      setLoading(false)
    })
  }

  const handleCancel = () => {
    navigate("/")
  }

  return (
    <>
      <Container maxWidth="xs">
        <Box
          sx={{
            mt: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >

          <img src="/images/logo.png" alt='logo' width={100} />
                    
          {loading && <CircularProgress />}

          <Typography sx={{mt:4}}>
              Please login
          </Typography>

          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              type='email'
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />

            { error? <Typography color="red">{error}</Typography> : <>&nbsp;</>} 

          </Box>

        </Box>

        <Box
          gap={4}
          sx={{
            mt: 3,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleLogin}
            disabled={loading}
          >
            Login
          </Button>
          <Button
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Box>

      </Container>      
    </>
  )
}

export default Login
