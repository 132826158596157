import React from 'react';
import { CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NotFoundPage from './pages/NotFoundPage';
import LomV1 from './pages/LomV1';
import Home from './pages/Home';
import ButtonAppBar from './components/navbar/navbar';
import Login from './pages/Login';
import Footer from './components/footer';
import { APP_COMPANY_NAME, APP_VERSION } from './utils/constants';
import { AuthProvider } from './context/AuthContext';
import { ColorModeProvider } from './context/ColorModeContext';


function App() {

  return (
    <ColorModeProvider>
      <AuthProvider>
        <CssBaseline />
        <ButtonAppBar title={APP_COMPANY_NAME} />
        <Router>
          <Routes>
            <Route path='/lomv1' element={<LomV1 />} />
            <Route path='/' element={<Home />} />
            <Route path='/home' element={<Home />} />
            <Route path='/login' element={<Login />} />
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        </Router>

        <Footer comanyName={APP_COMPANY_NAME} appVersion={APP_VERSION} />
      </AuthProvider>
    </ColorModeProvider>
  );
}

export default App;
