import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings'
import { 
  AppRegistration,
  Work,
  Login as LoginIcon,
  Logout as LogoutIcon
} from '@mui/icons-material';
import AuthContext from '../../context/AuthContext'
import RequireAdmin from '../RequireAdmin';
import RequireLogin from '../RequireLogin';
import RequireStuffUser from '../RiquireStuffUser';

export type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface Props {
  state: any,
  toggleDrawer: Function
}

export default function TemporaryDrawer({state, toggleDrawer}: Props) {

  const { user, logoutUser } = useContext(AuthContext)

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <>
        <List>
          <ListItem key={'home'} disablePadding>
            <ListItemButton href='/'>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
        </List>
      </>

      <RequireLogin>
        <Divider />
        <List>
          <ListItemText secondary="&nbsp;&nbsp;strategy" />
          <ListItem key={'settings'} disablePadding>
            <ListItemButton href='/settings'>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItemButton>
          </ListItem>
        </List>
      </RequireLogin>

      <RequireAdmin>
        <Divider />
        <List>
          {['Dashboard', 'Analytics', 'Send email'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton href={`/${text.toLowerCase()}`}>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </RequireAdmin>

      <RequireLogin>
        <Divider />
        <List>
          <ListItemText secondary="&nbsp;&nbsp;testing" />
          <RequireStuffUser>
            <ListItem key={'capitalcom_3'} disablePadding>
              <ListItemButton href='/capitalcom/test'>
                <ListItemIcon>
                  <Work />
                </ListItemIcon>
                <ListItemText primary="Test Services" />
              </ListItemButton>
            </ListItem>
          </RequireStuffUser>
        </List>  
      </RequireLogin>

      <RequireAdmin>
        <Divider />
        <List>
          <ListItemText secondary="&nbsp;&nbsp;admin" />
          <ListItem key={'admin_1'} disablePadding>
            <ListItemButton href='/admin/register'>
              <ListItemIcon>
                <AppRegistration />
              </ListItemIcon>
              <ListItemText primary="Register accounts" />
            </ListItemButton>
          </ListItem>
        </List>
      </RequireAdmin>

      <Divider />
      <List>
        <ListItem key={'general_1'} disablePadding>
          { user?  (
            <ListItemButton onClick={logoutUser}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          ) : (
            <ListItemButton href='/login'>
            <ListItemIcon>
              <LoginIcon />
            </ListItemIcon>
            <ListItemText primary="Login" />
          </ListItemButton>

          )}
        </ListItem>

      </List>
    </Box>
  );

  return (
    <div>
      {(['right'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
