import React from 'react'
import { Typography, Link, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

interface Props {
  open: boolean,
  setOpen: Function
}

 const ImpressumDialog = (props: Props) => {
  const {open, setOpen} = props

  return (
    <Dialog open={open}>
      <DialogTitle>Impressum</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography fontWeight="bold" sx={{ mt: 0.25, mb: 0.25 }}>
            E-mail:
          </Typography>
          <Typography>
            <Link href="mailto:info@lom.ch" underline="hover" color="inherit" target="_blank" >
              info@lom.ch
            </Link>
          </Typography>
          <Typography fontWeight="bold" sx={{ mt: 2, mb: 0.25 }}>
            Address:
          </Typography>
          <Typography>
            Lombriser & Co.<br />
            Soft- & Hardware Engineering<br />
            Oberweg 8<br />
            7203 Trimmis<br />
            Switzerland
          </Typography>
          <Typography fontWeight="bold" sx={{ mt: 2, mb: 0.25 }}>
            Legal Form:
          </Typography>
          <Typography>
            Limited Partnership (Kommanditgesellschaft)
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={evt => setOpen(false)}>OK</Button>
      </DialogActions>
    </Dialog>
  )
 }
 
 export default ImpressumDialog
 