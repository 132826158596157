import React from 'react'
import { Container, Grid, IconButton, Typography } from '@mui/material';
import { Email } from '@mui/icons-material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ImpressumDialog from '../ImpressumDialog';
import { useTheme } from '@mui/material/styles';
import ColorModeContext from '../../context/ColorModeContext';
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';

interface Props {
  comanyName: string,
  appVersion: string
}

const Footer = (props: Props) => {
  const { comanyName, appVersion } = props;

  const [impressumOpen, setImpressumOpen] = React.useState(false);

  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  return (
    <Container sx={{
      mt: -15,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "fixed",
      left: 0, 
      right: 0, 
      bottom: 15,
    }}>
      <Grid container justifyContent="center" sx={{ mt: 5, mb: 0.5 }}>
        <IconButton href="mailto:info@lom.ch">
          <Email />
        </IconButton>
        <IconButton href="https://www.linkedin.com/company/lombriser-co/" target="_blank" >
          <LinkedInIcon sx={{ color: '#0e76a8' }} />
        </IconButton>
        <IconButton size="small" onClick={evt => setImpressumOpen(true)} sx={{ mt: -0.25, ml: 0.25 }}>
          §
        </IconButton>
        <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
          {theme.palette.mode === 'dark' ? 
            <WbSunnyRoundedIcon />
          : <ModeNightRoundedIcon />
          }
        </IconButton>
      </Grid>
      <Typography variant="caption" color="textSecondary">
        © {new Date().getFullYear()} {comanyName} (version {appVersion})
      </Typography>

      <ImpressumDialog open={impressumOpen} setOpen={setImpressumOpen} />

    </Container>
  )
}

export default Footer
