import React from 'react'
import './lomeng.css'
import { Container, Grid } from '@mui/material'

const LomV1 = () => {
  return (
    <Container>
      <Grid
        container
        justifyContent="center"
        sx={{ mt:5, flexGrow: 1 }}
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item>
          <div className="block">
            <div id="hardware-logo" className="pic"></div>
            <div id="hardware">
              <h2>Hardware-Entwicklung</h2>
              Kundenspezifische Lösungen für Kommunikation, Regelung oder anderen Applikationen.
            </div>
          </div>
        </Grid>
        <Grid item>
          <div className="block">
            <div id="software-logo" className="pic"></div>
            <div id="software">
              <h2>Software-Entwicklung</h2>
              Anbindung von embedded-Geräten an das Internet, Steuerung durch mobile Geräte und vieles mehr.
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

export default LomV1
