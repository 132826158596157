import React from 'react'
import { Box, Alert, alpha } from '@mui/material'

const UnderConstruction = () => {
  return (
    <div>
      <Box 
        sx={(theme) => ({

          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          backgroundImage:
            theme.palette.mode === 'light'
              ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
              : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
          backgroundSize: '100% 20%',
          backgroundRepeat: 'no-repeat',
          mt: 20,
        })}
      >
        <Alert severity="warning">under construction...</Alert>
      </Box>
    </div>
  )
}

export default UnderConstruction
